<template>
    <div>
        <div v-bind:class="{ 'form-group': selectedType === 'Circuit' }" class="row">
            <label class="col-md-5 col-form-label">Type</label>
            <div class="col-md-7">
                <select
                    class="form-control"
                    v-model="selectedType"
                    data-placeholder="Select a Workout Type"
                    name="type">
                    <option
                        v-for="(type, index) of types"
                        :key="type + '-' + index"
                        :value="type"
                    >{{ type }}
                    </option>
                </select>
            </div>
        </div>
        <div class="row" v-if="selectedType === 'Circuit'">
            <label class="col-md-5 col-form-label">Rounds</label>
            <div class="col-md-7">
                <input
                    v-model="value"
                    class="form-control"
                    type="number"
                    name="rounds"
                    placeholder="0"
                    max="99">
            </div>
        </div>
    </div>
</template>

<script>
export default {
  mounted() {
    this.selectedType = this.currentOption ?? null;
    this.value = this.currentValue;
  },
  props: {
    currentOption: {
      type: String,
      require: false,
    },
    currentValue: {
      type: Number,
      require: false,
    },
    types: {
      type: Array,
      require: true,
    }
  },
  data() {
    return {
      selectedType: null,
      value: null,
    }
  },
}
</script>
