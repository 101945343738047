<template>
  <div class="card">
    <div class="card-header">
        <h3 class="card-title">Categories</h3>
    </div>
    <div class="table-responsive">
      <table class="table table-hover mb-0">
        <thead>
        <tr>
          <th>Title</th>
          <th>Sub Categories</th>
          <th>Workouts</th>
          <th v-if="custom_workouts === false">Actions</th>
        </tr>
        </thead>
        <draggable
            :list="categories"
            handle=".handle"
            tag="tbody">
          <tr
              v-for="(category, index) of categories"
              :key="index"
          >
            <td>
                <i class="fa fa-align-justify handle"></i>
                <a :href="`/${endpoint}/${category.id}`">{{ category.name }}</a>
            </td>
            <td>{{ category.children.length > 0 ? category.children.length : '-' }}</td>
            <td v-if="!custom_workouts">{{ category.workouts.length > 0 ? category.workouts.length : '-' }}</td>
            <td v-if="custom_workouts">{{ category.custom_workouts.length > 0 ? category.custom_workouts.length : '-' }}</td>
            <td class="actions" v-if="!custom_workouts">
              <a
                  :href="'/' + endpoint + '/' + category.id"
                  class="disable-item action-control" data-toggle="tooltip"
                  title="View">
                <i aria-hidden="true" class="fa fa-eye"></i>
              </a>
              <a :href="'/' + endpoint + '/' + category.id + '/edit'"
                 class="edit-item action-control" data-toggle="tooltip" title="Edit">
                <i aria-hidden="true" class="fa fa-cog"></i>
              </a>
              <a :data-action="'/' + endpoint + '/' + category.id + '/delete'" :data-label="category.name"
                 class="delete-item action-control"
                 data-target="#delete-modal"
                 data-toggle="modal"
                 href="#">
                <i aria-hidden="true" class="fa fa-ban"></i>
              </a>
            </td>
            <td class="d-none">
              <input :name="'categories[' + index + '][id]'" :value="category.id" type="number">
              <input :name="'categories[' + index + '][order]'" :value="index" type="number">
            </td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  mounted() {
    this.categories = this.data.data;
    this.route = `${this.url}/${this.endpoint}`;
  },
  props: {
    data: {
      type: Object,
      require: true,
    },
    url: {
      type: String,
      require: true,
    },
    endpoint: {
      type: String,
      require: true,
    },
    custom_workouts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categories: [],
      route: null,
    }
  }
}
</script>
