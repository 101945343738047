<template>
    <div>
        <input v-model="exercise.id" :name="inputName + '[selected_exercise]'" type="hidden">
        <input :name="inputName + '[superset_identifier]'" :value="supersetIdentifier" type="hidden">
        <table class="table table-borderless table-sm" style="table-layout: fixed">
            <thead>
            <tr>
                <th style="width: 48px" class="text-center"></th>
                <th>
                    <select v-model="leftColumn" :name="inputName + '[left_column]'" class="form-control">
                        <option disabled :value="null">- Select Left Column -</option>
                        <option v-for="(attribute, index) of exerciseAttributes" :key="attribute + '-' + index"
                                :value="index">
                            {{ index }}
                        </option>
                    </select>
                </th>
                <th>
                    <select v-model="rightColumn" :name="inputName + '[right_column]'" class="form-control">
                        <option disabled :value="null">- Select Right Column -</option>
                        <option v-for="(option, index) of exerciseAttributes[leftColumn]" :key="index" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </th>
                <th style="width: 48px" class="text-center"></th>
            </tr>
            </thead>
            <draggable :list="exercise.sets.values" handle=".drag-handle" tag="tbody">
                <tr v-for="(pair, index) of exercise.sets.values" :key="index">
                    <td class="text-center">
                        <i class="fa fa-align-justify drag-handle"></i>
                    </td>
                    <td>
                        <input v-model="pair[0]" :name="inputName + '[sets][' + index + '][left_value]'"
                               class="form-control" type="number">
                    </td>
                    <td>
                        <template v-if="rightColumn !== 'N/A'">
                            <input v-model="pair[1]" :name="inputName + '[sets][' + index + '][right_value]'"
                                   class="form-control" type="number">
                        </template>
                    </td>
                    <td class="text-center">
                        <i class="fa fa-minus align-right" @click="removeSet(index)"></i>
                    </td>
                </tr>
            </draggable>
        </table>

        <div>
            <input
                v-model="text_overwrite"
                :true-value="true"
                :name="inputName + '[text_overwrite]'"
                type="checkbox"
            >
            <label class="control-label">Text overwrite</label>
        </div>
        <template v-if="text_overwrite === true">
            <label class="control-label">Value</label>
            <div>
                <input
                    v-model="value_overwrite"
                    :name="inputName + '[value_overwrite]'"
                    class="form-control"
                    type="text"
                >
            </div>
        </template>

    </div>
</template>

<script>
export default {
    props: {
        exercise: {
            type: Object,
            require: true,
        },
        inputName: {
            type: String,
            require: true,
        },
        exerciseAttributes: {
            type: Object,
            require: true,
        },
        supersetIdentifier: {
            type: String,
            require: false,
        }
    },
    watch: {
        exercise(value) {
            this.leftColumn = value.attributes.leftColumn;
            this.rightColumn = value.attributes.rightColumn;
        }
    },
    data() {

        return {
            leftColumn: this.exercise.sets.left,
            rightColumn: this.exercise.sets.right,
            text_overwrite: this.exercise.text_overwrite,
            value_overwrite: this.exercise.value_overwrite

        }
    },
    methods: {
        addSet() {
            const values = this.exercise.sets.values;
            const lastSet = values.length > 0 ? values[values.length - 1] : [0, 0];
            values.push([lastSet[0], lastSet[1]]);
        },
        removeSet(index) {
            this.exercise.sets.values.splice(index, 1);
        },
    }
}
</script>
