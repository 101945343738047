<template>
    <div>
        <div class="form-group">
            <label class="control-label">Title</label>
            <input
                :name="inputName + '[title]'"
                v-model="featuredItem.title"
                class="form-control"
                type="text"
            >
        </div>
        <div class="form-group">
            <label class="control-label">Description</label>
            <textarea
                :name="inputName + '[description]'"
                v-model="featuredItem.description"
                class="form-control"
            ></textarea>
        </div>

        <div class="form-group">
            <label class="control-label">Image</label>
            <input-image
                :apiBaseUrl="apiBaseUrl"
                :inputName="inputName + '[file_id]'"
                :selectedFileId="featuredItem.file_id"
                :imageUrl="featuredItem.file.url"
            ></input-image>
        </div>

        <div class="form-group">
            <label class="control-label">Type</label>
            <select
                v-model="selectedType"
                :name="inputName + '[workout_type]'"
                class="form-control"
            >
                <option
                    v-for="(type, index) of workoutTypes"
                    :key="index"
                    :value="type">
                    {{ type }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <label class="control-label">Target</label>
            <template v-if="selectedType === 'link'">
                <input
                    :name="inputName + '[target]'"
                    :value="featuredItem.target"
                    class="form-control"
                    type="text"
                >
            </template>
            <template v-if="selectedType === 'workout'">
                <select2 :options="workoutsFetch" v-model="featuredItem.target"
                         :name="inputName + '[target]'">
                </select2>

            </template>
        </div>
        <div class="form-group">
            <input
                v-model="status"
                :true-value="1"
                :name="inputName + '[status]'"
                type="checkbox"
            >
            <label class="control-label">Active</label>
        </div>
        <div class="clearfix">
            <button
                class="btn btn-default"
                type="button"
                @click="onRemove($event, index)"
            >
                Remove
            </button>
        </div>
    </div>
</template>

<script>

import Select2 from './Select2.vue';

export default {
    components: {
        "select2": Select2
    },
    props: {
        featuredItem: {
            type: Object,
            require: true,
        },
        apiBaseUrl: {
            type: String,
            require: true,
        },
        inputName: {
            type: String,
            require: true,
        },
        workoutTypes: {
            type: Array,
            require: true,
        },
        workouts: {
            type: Array,
            require: true,
        },
        index: {
            type: Number,
            require: true,
        },

    },
    data() {
        return {
            selectedType: this.featuredItem.type,
            target: this.featuredItem.target,
            status: this.featuredItem.status,
        }
    },
    computed: {
        workoutsFetch() {
            return this.workouts.map(workout => {
                return {
                    id: workout.id,
                    text: workout.title
                }
            });
        },
    },
    methods: {
        onRemove($event, index) {
            this.$emit('removeItem', index);
        }

    },

}

</script>
