<template>
<div>
  <div class="input-group">
    <div class="form-group">
      <label>Exercise</label>
      <input
          class="d-none"
          :name="'exercises[' + exerciseIndex + '][superset_identifier]'"
          :value="supersetIdentifier">
      <input
          :name="'exercises[' + exerciseIndex + '][model_id]'"
          :value="selectedExercise.id"
          class="d-none">
      <select
          :name="'exercises[' + exerciseIndex + '][selected_exercise]'"
          class="form-control">
        <option
            v-for="exercise of exercises"
            :selected="exercise.id === selectedExercise.id"
            :key="exercise.id"
            :value="exercise.id">{{ exercise.name }}
        </option>
      </select>
    </div>
  </div>
  <div>
    <div>
      Sets:
    </div>
    <div class="clearfix">
      <button
          class="btn btn-default"
          type="button"
          @click="addSet">Add set
      </button>
    </div>
    <div class="card-body">
      <div v-for="(set, index) of sets" :key="index">
        <exercise-set
            @updateSet="onUpdateSet($event, set, index)"
            :exerciseIndex="exerciseIndex"
            :setIndex="index"
            :set="set"
            :leftValue="selectedAttribute"
            :rightValue="selectedRightColumn"
        ></exercise-set>
      </div>
    </div>
    <div class="clearfix">
      <button
          class="btn btn-default margin-top-10"
          type="button"
          v-if="supersetIdentifier"
          @click="addToSuperset"
      >Add to Superset
      </button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted() {
    if (this.selectedExercise.exercise_attribute_exercises) {
      this.selectedExercise.exercise_attribute_exercises.forEach(attribute => {
        this.sets[attribute.set_number - 1] = {
          ...this.sets[attribute.set_number - 1],
          set_number: attribute.set_number,
          [attribute.exercise_attribute.key]: attribute.exercise_attribute.value,
        };
      });
    } else {
    }
  },
  props: {
    exerciseIndex: {
      type: Number,
      require: true,
    },
    selectedExercise: {
      type: Object,
      require: true,
    },
    exercises: {
      type: Array,
      require: true,
    },
    supersetIdentifier: {
      type: String,
      require: true,
    },
    exerciseAttributes: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      sets: [],
      modelExercise: null,
      selectedAttribute: null,
      selectedRightColumn: null,
    }
  },
  methods: {
    addSet() {
      this.sets.push({

      })
    },
    onUpdateSet(changes, set, index) {
      this.sets.splice(index, 1, { ...set, ...changes });
    },
    addToSuperset() {
      this.$emit('addToSuperset', this.supersetIdentifier);
    }
  },
}
</script>
