<template>
    <div>
        <div>

            <div class="clearfix">
                <button
                    class="btn btn-primary margin-top-10"
                    type="button"
                    @click="addProgressiveSwap">
                    <i class="fa fa-plus"></i>
                    Add Progressive Swap
                </button>
            </div>
            <draggable
                :list="selectedProgressiveExercises"
                class="list-group"
                handle=".handle"
                tag="ul">
                <li
                    v-for="(item, index) of selectedProgressiveExercises"
                    :key="item.id + '-' + index"
                    class="list-group-item">
                    <i class="fa fa-align-justify handle"></i>
                   {{selectedProgressiveExercises.length - index }} {{ exerciseName(item.id) }}
                    <i
                        class="fa fa-minus align-right"
                        style="float: right"
                        @click="onRemoveProgressive(index)"
                    ></i>
                    <input :name="'swap[' + (selectedProgressiveExercises.length - index) + '][index]'" :value="selectedProgressiveExercises.length - index" type="hidden">
                  <input
                      :value="item.id"
                      :name="'swap[' + (selectedProgressiveExercises.length - index) + '][exercise_id]'"
                      type="hidden">
                </li>
            </draggable>

            <div class="swaps">
                <b><i class="fa fa-caret-up"></i> Progressive Swaps <i class="fa fa-caret-up"></i></b>
            </div>

            <p class="main-exercise">{{ mainExerciseName }}</p>

            <div class="swaps">
                <b><i class="fa fa-caret-down"></i> Regressive Swaps <i class="fa fa-caret-down"></i></b>
            </div>

            <draggable
                :list="selectedRegressiveExercises"
                class="list-group"
                handle=".handle"
                tag="ul">
                <li
                    v-for="(item, index) of selectedRegressiveExercises"
                    :key="item.id"
                    class="list-group-item">
                    <i class="fa fa-align-justify handle"></i>
                    {{index + 1}} {{ exerciseName(item.id) }}
                    <i
                        class="fa fa-minus align-right"
                        style="float: right"
                        @click="onRemoveRegressive(index)"
                    ></i>
                  <input :name="'swap[' + (index + 1) * -1 + '][index]'" :value="(index + 1) * -1" type="hidden">
                  <input
                      :name="'swap[' + (index + 1) * -1 + '][exercise_id]'"
                      :value="item.id"
                      type="hidden">
                </li>
            </draggable>

            <div class="clearfix">
                <button
                    class="btn btn-primary margin-top-10"
                    type="button"
                    @click="addRegressiveSwap">
                    <i class="fa fa-plus"></i>
                    Add Regressive Swap
                </button>
            </div>

            <modal name="add-progressive-swap" adaptable="true" height="auto">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Add Progressive Swap</h4>
                        <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            @click="dismissModalProgressive"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label class="col-form-label">Select an Exercise</label>

                        <select2 :name="'swap[' + index + '][' + 'exercise_id]'" :options="exercisesFetch"
                                 v-model="selectedProgressiveExercise">
                            <option disabled :value="null">-- Select an Exercise --</option>
                        </select2>

                    </div>
                    <div class="modal-footer justify-content-between">
                        <button
                            type="button"
                            class="btn btn-default"
                            @click="dismissModalProgressive"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="selectProgressiveExercise"
                        >
                            Select Exercise
                        </button>
                    </div>
                </div>
            </modal>
            <modal name="add-regressive-swap" adaptable="true" height="auto">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Add Regressive Swap</h4>
                        <button
                            type="button"
                            class="close"
                            @click="dismissModalRegressive"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label class="col-form-label">Select an Exercise</label>

                        <select2 :name="'swap[' + index + '][' + 'exercise_id]'" :options="exercisesFetch"
                                 v-model="selectedRegressiveExercise">
                            <option disabled :value="null">-- Select an Exercise --</option>
                        </select2>

                    </div>
                    <div class="modal-footer justify-content-between">
                        <button
                            type="button"
                            class="btn btn-default"
                            @click="dismissModalRegressive"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="selectRegressiveExercise"
                        >
                            Select Exercise
                        </button>
                    </div>
                </div>
            </modal>


        </div>
    </div>
</template>

<script>

import draggable from "vuedraggable";
import Select2 from './Select2.vue';

export default {
    components: {
        draggable,
        "select2": Select2
    },
    name: "ExerciseSwapWrapperComponent",
    mounted() {
      if (!!this.oldSelectedSwaps) {
        Object.keys(this.oldSelectedSwaps).map(key => {
          const item = this.oldSelectedSwaps[key];
          if (item.index > 0) {
            this.selectedProgressiveExercises.push({
              id: item.exercise_id,
              index: item.index,
            });
          }
          if (item.index < 0) {
            this.selectedRegressiveExercises.push({
              id: item.exercise_id,
              index: item.index,
            });
          }
        });
          this.selectedProgressiveExercises.reverse();
      }
    },
    props: {
        exercises: {
            type: Array,
            require: true,
        },
        exercise: {
            type: Object,
            require: false,
        },
        index: {
            type: Number,
            require: true,
        },
        selectedSwap: {
            type: Object,
            require: true,
        },
        oldSelectedSwaps: {
          type: Object,
          require: false,
        }
    },
    data() {
        return {
            selectedProgressiveExercises: this.exercise.exercise_swaps.filter(function (item) {
              return item.type !== 'regressive';

            }).map(item => {
                return {
                    id: item.exercise_swap_id,
                    index: item.index,
                };
            }),
            selectedRegressiveExercises: this.exercise.exercise_swaps.filter(function (item) {
              return item.type !== 'progressive';

            }).map(item => {
                return {
                    id: item.exercise_swap_id,
                    index: item.index,
                };
            }),
            exerciseSwaps: [],
            selectedProgressiveExercise: null,
            selectedRegressiveExercise: null,

        };
    },
    computed: {
        exercisesFetch() {
            return this.exercises.map(exercise => {
                return {
                    id: exercise.id,
                    text: exercise.name
                }
            });
        },
        mainExerciseName() {
            return this.exercise.name
        },
    },
    methods: {
        dismissModalRegressive() {
            this.$modal.hide('add-regressive-swap');
        },
        dismissModalProgressive() {
            this.$modal.hide('add-progressive-swap');
        },
        addRegressiveSwap() {
            this.$modal.show('add-regressive-swap',
                {exercises: this.exercises});
        },
        addProgressiveSwap() {
            this.$modal.show('add-progressive-swap',
                {exercises: this.exercises});
        },
        selectProgressiveExercise() {
            const selection = this.exercisesFetch.find(item => parseInt(item.id) === parseInt(this.selectedProgressiveExercise));
            this.selectedProgressiveExercises.unshift(selection);
            this.selectedProgressiveExercise = null;

            this.$modal.hide('add-progressive-swap');
        },
        selectRegressiveExercise() {
            const selection = this.exercisesFetch.find(item => parseInt(item.id) === parseInt(this.selectedRegressiveExercise));
            this.selectedRegressiveExercises.push(selection);
            this.selectedRegressiveExercise = null;

            this.$modal.hide('add-regressive-swap');
        },
        onRemoveProgressive(index) {
            this.selectedProgressiveExercises.splice(index, 1);
        },
        onRemoveRegressive(index) {
            this.selectedRegressiveExercises.splice(index, 1);
        },
        exerciseName(id){
            const selected = this.exercises.find(exercise => parseInt(exercise.id) === parseInt(id));
            return selected.name;
        },
    }
}
</script>

<style scoped>

</style>
