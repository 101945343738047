<template>
    <div>
        <input type="file" class="d-none" ref="fileInput" @change="onFileChange" accept="image/*">
        <input type="hidden" :name="`${name}[file_id]`" :value="fileId">
        <input v-for="(value, key) of extras" type="hidden" :name="`${name}[${key}]`" :value="`${value}`">
        <button type="button" v-if="previewUrl" class="img-thumbnail mb-2" @click="selectImage()">
            <img :src="previewUrl" class="img-fluid" />
        </button>
        <button v-if="previewUrl" type="button" class="btn btn-block btn-danger" @click="removeImage()">
            Remove Image
        </button>
        <button v-if="!previewUrl" type="button" class="btn btn-block btn-default" @click="selectImage()">
            <span v-if="uploading" class="button-progress" :style="{ width: `${uploadProgress}%` }"></span>
            <span class="button-text">{{ fileName || 'Select Image' }}</span>
        </button>
    </div>
</template>

<script>
    export default {
        props: {
            extras: {
                type: Object,
                require: false,
                default: [],
            },
            name: {
                type: String,
                require: true
            },
            href: {
                type: String,
                require: true,
            },
            value: {
                type: Object,
                require: false,
                default: {},
            },
        },
        data() {
            return {
                fileName: this.value['file_name'] || null,
                fileId: this.value['file_id'] || null,
                previewUrl: this.value['preview_url'] || null,
                file: null,
                uploading: false,
                uploadProgress: 50,
            }
        },
        methods: {
            onFileChange(event) {
                const fileInput = event.target;
                [this.file] = fileInput.files;
                this.fileName = this.file.name;
                this.previewUrl = null;
                this.uploadFile(this.file);
            },
            removeImage() {
                this.fileName = null;
                this.fileId = null;
                this.previewUrl = null;
                this.$refs.fileInput.value = null;
            },
            selectImage() {
                this.$refs.fileInput.click();
            },
            uploadFile(file) {
                this.uploading = true;
                this.uploadProgress = 50;

                const formData = new FormData();
                formData.append('file', file);

                axios.post(this.href, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: event => {
                        this.uploadProgress = Math.round((100 / event.total) * event.loaded);
                    }
                }).then(({ data }) => {
                    this.fileId = data.id;
                    this.previewUrl = data.path
                }).finally(() => {
                    this.uploading = false;
                    this.uploadProgress = 0;
                });
            },
        }
    }
</script>

<style scoped>
    .btn {
        position: relative;
        outline: none;
    }

    .btn .button-progress {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgba(56, 193, 114, 0.3);
    }

    .btn .button-text {
        position: relative;
        z-index: 10;
    }
</style>
