<template>
    <div>
        <draggable :list="exercises" handle=".drag-handle" tag="div">
            <div
                v-for="(item, index) of exercises"
                :key="`${item.id}-${index}`"
                class="card card-default"
            >
                <div
                    class="card-header"
                    @click="toggleOpen(item)"
                    style="cursor: pointer"
                >
                    <h4 class="card-title">
                        <i class="fa fa-align-justify drag-handle mr-2"></i>
                        {{ item.name }}
                    </h4>
                    <i
                        :class="{
                            'fa-chevron-down': item.isOpen,
                            'fa-chevron-up': !item.isOpen
                        }"
                        class="fa mt-1 float-right"
                    ></i>
                </div>
                <div class="card-body" :class="{ collapse: !item.isOpen }">
                    <workout-exercise
                        :ref="'workout-exercise-' + index"
                        :exercise="item"
                        :exerciseAttributes="exerciseAttributes"
                        :inputName="
                            inputName + '[superset][exercises][' + index + ']'
                        "
                        :supersetIdentifier="superset.id"
                    >
                    </workout-exercise>
                </div>
                <div class="card-footer" :class="{ collapse: !item.isOpen }">
                    <div class="clearfix">
                        <button
                            class="btn btn-primary float-left"
                            type="button"
                            @click="addSet(index)"
                        >
                            Add Set
                        </button>
                        <button
                            class="btn btn-danger float-right"
                            type="button"
                            @click="removeExercise(index)"
                        >
                            Remove Exercise
                        </button>
                    </div>
                </div>
            </div>
        </draggable>
        <div class="clearfix">
            <button
                class="btn btn-default margin-top-10"
                type="button"
                @click="openModal"
            >
                Add Exercise
            </button>
        </div>
        <modal name="add-to-superset" adaptable="true" height="auto">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Exercise</h4>
                    <button
                        type="button"
                        class="close"
                        @click="dismissModal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label class="col-form-label">Select an Exercise</label>
                    <select2 :options="exerciseOptionsFetch"  v-model="selectedExercise">
                        <option disabled :value="null">-- Select an Exercise --</option>
                    </select2>
                </div>
                <div class="modal-footer justify-content-between">
                    <button
                        type="button"
                        class="btn btn-default"
                        @click="dismissModal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        v-bind:class="{ disabled: !selectedExercise }"
                        class="btn btn-primary"
                        @click="selectExercise"
                    >
                        Select Exercise
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import Select2 from './Select2.vue';

export default {
    components: {
        draggable,
        "select2": Select2
    },
    mounted() {
        this.exercises = [];
        this.superset.exercises.forEach(item => {
            this.exercises.push({
                ...item,
                isOpen: false
            });
        });
    },
    computed: {
        exerciseOptionsFetch() {
            return this.exerciseOptions.map(exerciseOption => {
                return {
                    id: exerciseOption.id,
                    text: exerciseOption.name
                }
            });
        },
    },
    props: {
        superset: {
            type: Object,
            require: true
        },
        exerciseAttributes: {
            type: Object,
            require: true
        },
        exerciseOptions: {
            type: Array,
            require: true
        },
        inputName: {
            type: String,
            require: true
        }
    },
    data() {
        return {
            exercises: [],
            selectedExercise: null
        };
    },
    methods: {
        addSet(index) {
            const [workoutExercise] = this.$refs["workout-exercise-" + index];
            workoutExercise.addSet();
        },
        removeExercise(index) {
            this.exercises.splice(index, 1);
        },
        openModal() {
            this.$modal.show("add-to-superset", {
                exerciseOptions: this.exerciseOptions
            });
        },
        dismissModal() {
            this.$modal.hide("add-to-superset");
            this.selectedExercise = null;
        },
        selectExercise() {

            const selection = this.exerciseOptions.find(item => parseInt(item.id) === parseInt(this.selectedExercise));

            this.exercises.push({
                isOpen: true,
                id: selection.id,
                name: selection.name,
                type: "exercise",
                sets: {
                    left: null,
                    right: null,
                    values: [[0, 0]]
                }
            });
            this.dismissModal();
        },
        toggleOpen(item) {
            item.isOpen = !item.isOpen;
        }
    }
};
</script>
