<template>
    <table class="table mb-0" id="workout_information">
        <thead>
        <tr>
            <th>Label</th>
            <th>Value</th>
            <th style="width: 60px">
                <a href="#" class="btn btn-add btn-primary" id="add_row" @click="addItem">
                    <span class="fa fa-plus" aria-hidden="true"></span>
                </a>
            </th>
        </tr>
        </thead>
        <tbody>
            <workout-attribute
                v-for="(attribute, index) in attributes" v-bind:key="index"
                :name="`${name}[${index}]`"
                :label="attribute.key"
                :value="attribute.value"
                @remove="removeItem(index)"
            >
            </workout-attribute>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                require: true
            },
            values: {
                type: Array,
                require: false,
            },
        },
        data() {
            return {
                attributes: this.values || [{
                    key: '',
                    value: '',
                }],
            }
        },
        methods: {
            addItem(event) {
                event.preventDefault();
                this.attributes.push({
                    key: '',
                    value: '',
                });
            },
            removeItem(index) {
                this.attributes.splice(index, 1);
            },
        }
    }
</script>
