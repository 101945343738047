<template>
  <div>
    <div>
      <label class="col-sm-4 col-md-3 control-label">Select Category</label>
      <div class="col-sm-8 col-md-9">
        <select
            v-model="selectedCategory"
            class="form-control select2 select2-hidden-accessible"
        >
          <option value="null">-- Select a Category --</option>
          <option
              v-for="(category, index) of workoutCategories"
              :key="index"
              :value="category"
          >{{ category.name }}
          </option>
        </select>
        <input
            v-if="!!selectedCategory"
            :name="inputName + '[selected_category]'"
            :value="selectedCategory.id"
            class="d-none"
            type="number"
        >
      </div>
    </div>
    <div v-if="!!selectedCategory">
      <div class="col-sm-8 col-md-9">
        <select
            :name="inputName + '[workout_ids][]'"
            class="form-control select2 select2-hidden-accessible"
        >
          <option value="null">-- Select a Workout --</option>
          <option
              v-for="(workout, index) of selectedCategory.workouts"
              :key="index"
              :value="workout.id"
          >{{ workout.title }}
          </option>
        </select>
      </div>
      <div class="col-sm-8 col-md-9">
        <select
            :name="inputName + '[workout_ids][]'"
            class="form-control select2 select2-hidden-accessible"
        >
          <option value="null">-- Select a Workout --</option>
          <option
              v-for="(workout, index) of selectedCategory.workouts"
              :key="index"
              :value="workout.id"
          >{{ workout.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    workoutCategories: {
      type: Array,
      require: true,
    },
    inputName: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      selectedCategory: null,
    };
  },
  watch: {
    selectedCategory(value) {
      console.log(value);
    }
  }
}
</script>
