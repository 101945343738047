<template>
    <select class="form-control" :name="name">
        <slot></slot>
    </select>
</template>


<script>

export default {
    props: ["options", "value", "name"],

    data() {
        return {
            msg: "hello vue"
        }
    },
    mounted: function () {
        $(document).ready(() => {
            $(this.$el)
                // init select2
                .select2({
                    data: this.options,
                })
                .val(this.value)
                .trigger("change")
                // emit event on change.
                .on("change", () => {
                    this.$emit("input", this.$el.value);
                });
        });
    },
    watch: {
        value: function (value) {
            // update value
            $(this.$el)
                .val(value)
                .trigger("change");
        },
        options: function (options) {
            // update options
            $(this.$el)
                .empty()
                .select2({data: options});
        }
    },
    destroyed: function () {
        $(this.$el)
            .off()
            .select2("destroy");
    }
}
</script>
<style>
    .select2-container {
        width: 100% !important;
    }
</style>
