<template>
  <div>
    <vsa-list>
      <draggable
          :list="compItems"
          class="list-group"
          handle=".handle"
          tag="ul">
        <li
            v-for="(item, index) of compItems"
            :key="item.id"
            class="list-group-item">
          <i class="fa fa-align-justify handle"></i>
          <vsa-item>
            <vsa-heading>{{ item.title }}</vsa-heading>
            <vsa-content>
              <featured-item
                  :featuredItem="item"
                  :index="index"
                  :inputName="'featured_items[' + index + ']'"
                  :apiBaseUrl="apiBaseUrl"
                  :workoutTypes="workoutTypes"
                  :workouts="workouts"
                  @removeItem="onRemoveItem"
              ></featured-item>
            </vsa-content>
          </vsa-item>
        </li>
      </draggable>
    </vsa-list>
    <div class="clearfix" style="padding-top: 10px;">
      <button
          class="btn btn-default"
          type="button"
          @click="onAddItem">Add
      </button>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  mounted() {
  },
  props: {
    featuredItems: {
      type: Array,
      require: true,
    },
    workoutTypes: {
      type: Array,
      require: true,
    },
    workouts: {
      type: Array,
      require: true,
    },
    apiBaseUrl: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      compItems: [...this.featuredItems],
    }
  },
  methods: {
    onRemoveItem(value) {
      this.compItems.splice(value, 1);
    },
    onAddItem() {
      this.compItems.push({
        title: 'New Item',
        description: '',
        duration: 0,
        workout_type: '',
        target: '',
        status: true,
        file: {
          path: null
        },
      });
    }
  }
}
</script>

<style scoped>
button.vsa-item__trigger {
  padding: 5px;
}
</style>
