<template>
    <div class="form-group">
        <label class="col-sm-4 col-md-3 control-label">Exercise</label>

        <select2  :name="'swap[' + index + '][' + 'exercise_id]'" :options="exercisesFetch"  v-model="selectedSwap.exercise_swap_id">
            <option disabled :value="null">-- Select an Exercise --</option>
        </select2>

        <div>
            <label class="col-sm-4 col-md-3 control-label">Swap Index</label>
            <input
                class="form-control"
                type="number"
                :value="selectedSwap.index"
                :name="'swap[' + index + '][' + 'index]'">
        </div>
        <input
            class="d-none"
            :name="'swap[' + index + '][' + 'model_id]'"
            :value="selectedSwap.id"
        >
    </div>
</template>

<script>
import Select2 from './Select2.vue';

export default {
    components: {
        "select2": Select2
    },

    name: "ExerciseSwapComponent",
    mounted() {
    },
    props: {
        exercises: {
            type: Array,
            require: true,
        },
        index: {
            type: Number,
            require: true,
        },
        selectedSwap: {
            type: Object,
            require: true,
        }
    },
    computed: {
        exercisesFetch() {
            return this.exercises.map(exercise => {
                return {
                    id: exercise.id,
                    text: exercise.name
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
