<template>
    <div>
        <draggable :list="sets" handle=".handle-div" tag="div">
            <div v-for="(pair, index) of sets" :key="index" class="row">
                <div class="col flex-grow-0">
                    <i class="fa fa-align-justify handle-div"></i>
                </div>
                <div class="col flex-grow-1">
                    <input v-model="sets[index][0]" :name="inputName + '[' + index + '][left_value]'"
                           class="form-control" type="number">
                </div>
                <div class="col flex-grow-1">
                    <input v-model="sets[index][1]" :name="inputName + '[' + index + '][right_value]'"
                           class="form-control" type="number">
                </div>
                <div class="col flex-grow-0">
                    <i class="fa fa-minus align-right" @click="removeSet(index)"></i>
                </div>
            </div>
        </draggable>
        <div class="clearfix">
            <button
                class="btn btn-default margin-top-10"
                type="button"
                @click="addSet()">Add set
            </button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        components: {
            draggable,
        },
        mounted() {
            this.leftColumn = this.leftLabel;
            this.rightColumn = this.rightLabel;
            this.sets = [...this.currentSets];
        },
        props: {
            currentSets: {
                type: Array,
                require: true,
            },
            leftLabel: {
                type: String,
                require: true,
            },
            rightLabel: {
                type: String,
                require: true,
            },
            inputName: {
                type: String,
                require: true,
            }
        },
        data() {
            return {
                sets: [],
                leftColumn: null,
                rightColumn: null,
            }
        },
        watch: {
            currentSets(value) {
                this.sets = [...value];
            },
            leftLabel(value) {
                this.leftColumn = value;
            },
            rightLabel(value) {
                this.rightColumn = value;
            }
        },
        methods: {
            addSet() {
                const lastSet = this.sets.length > 0 ? this.sets[this.sets.length - 1] : [0, 0];
                this.sets.push([lastSet[0], lastSet[1]]);
            },
            removeSet(index) {
                this.sets.splice(index, 1);
            }
        }
    }
</script>
