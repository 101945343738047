<template>
    <div>
        <div>
            <draggable
                :list="selectedItems"
                class="list-group"
                handle=".handle"
                tag="ul">
                <li
                    v-for="(item, index) of selectedItems"
                    :key="item.title + '-' + categoryId + '-' + '-' + index + '-' + item.id"
                    class="list-group-item">
                    <i class="fa fa-align-justify handle"></i>
                    {{ item.title }}
                    <i
                        class="fa fa-minus align-right"
                        style="float: right"
                        @click="onRemoveItem(index)"
                    ></i>
                    <input
                        :name="'categories[' + categoryId + '][selected_workouts][]'"
                        :value="item.id"
                        class="d-none"
                        type="number">
                </li>
            </draggable>
        </div>

        <button
            class="btn btn-default"
            type="button"
            style="margin-top: 5px;"
            @click="openModal"
        >
            Add Workout
        </button>
        <modal :name="categoryId + '-' + 'modal'">
            <div class="form-group">
                <label class="col-sm-4 col-md-3 control-label">Select a Workout</label>

                <select2 :options="workoutsFetch" v-model="selectedWorkout">
                    <option disabled :value="null">-- Select a Workout --</option>
                </select2>

            </div>
            <div class="clearfix">
                <button
                    class="btn btn-default"
                    type="button"
                    @click="closeModal">Add
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import Select2 from './Select2.vue';

export default {
    components: {
        draggable,
        "select2": Select2
    },
    mounted() {
    },
    props: {
        workouts: {
            type: Array,
            require: true,
        },
        selectedWorkouts: {
            type: Array,
            require: true,
        },
        categoryId: {
            type: String | Number,
            require: true,
        }
    },
    data() {
        return {
            selectedItems: this.selectedWorkouts.map(item => {
                return {
                    id: item.id,
                    title: item.title,
                };
            }),
            selectedWorkout: null,
        }
    },
    computed: {
        workoutsFetch() {
            return this.workouts.map(workout => {
                return {
                    id: workout.id,
                    text: workout.title
                }
            });
        },
    },
    methods: {
        onRemoveItem(index) {
            this.selectedItems.splice(index, 1);
        },
        openModal() {
            this.$modal.show(this.categoryId + '-' + 'modal',
                {workouts: this.workouts});
        },
        closeModal() {
            this.$modal.hide(this.categoryId + '-' + 'modal');

            const selection = this.workouts.find(item => parseInt(item.id) === parseInt(this.selectedWorkout));
            this.selectedItems.push(selection);
            this.selectedWorkout = null;
        },
    }
}
</script>
