<template>
  <div>
    <input
        v-if="!previewUrl"
        class="form-control"
        ref="file"
        type="file"
        @change="onInputChange">
    <input
        v-model="fileId"
        :name="inputName"
        class="d-none"
        type="number"
    >
    <div v-if="!!previewUrl">
      <img
          :src="previewUrl"
          class="img-fluid img-thumbnail mb-2">
    </div>
      <div class="clearfix">
          <button
              v-if="previewUrl"
              class="btn btn-default"
              type="button"
              style="margin-bottom: 5px;"
              @click="onRemoveImage">
              Remove
          </button>
      </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fileId = this.selectedFileId;
    this.previewUrl = this.imageUrl;
    if (!!this.fileId && !this.previewUrl) {
      this.getPreviewUrl();
    }
  },
  props: {
    inputName: {
      type: String,
      require: true
    },
    apiBaseUrl: {
      type: String,
      require: true,
    },
    imageUrl: {
      type: String,
      require: false,
    },
    selectedFileId: {
      type: Number,
      require: false,
    }
  },
  data() {
    return {
      file: '',
      fileId: null,
      previewUrl: null,
    }
  },
  methods: {
    onRemoveImage() {
      this.file = null;
      this.previewUrl = null;
    },
    onInputChange() {
      this.file = this.$refs.file.files[0];
      this.submit();
    },
    submit() {
      const obj = this;
      const formData = new FormData();
      formData.append('file', this.file);
      axios.post(
          `${this.apiBaseUrl}/files`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function (res) {
        obj.fileId = res.data.id;
        obj.previewUrl = res.data.path
      })
          .catch(function (err) {
            console.log(err);
          })
    },
    getPreviewUrl() {
      const obj = this;
      axios.get(
          `${this.apiBaseUrl}/files/${this.fileId}`,
      ).then(function (res) {
        obj.previewUrl = res.data.path;
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
}
</script>

<style scoped>

</style>
