<template>
    <div>
        <draggable :list="content" handle=".drag-handle" tag="div">
            <div v-for="(item, index) of content" :key="index" class="card">
                <div class="card-header">
                    <div class="card-title d-flex w-100 align-items-center">
                        <div class="flex-grow-0 mr-3">
                            <i class="fa fa-align-justify drag-handle"></i>
                        </div>
                        <div class="flex-grow-1">
                            <input :name="`${name}[${index}][question]`" v-model="item.question" class="form-control d-block" placeholder="Enter question" type="text">
                        </div>
                        <div class="flex-grow-0 ml-3">
                            <a href="#" class="fa fa-minus" @click="onRemoveItem(index)"></a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <ckeditor :editor="editor" :config="editorConfig" v-model="item.answer"></ckeditor>
                    <input :name="`${name}[${index}][answer]`" :value="item.answer" type="hidden">
                </div>
            </div>
        </draggable>
        <div class="clearfix pb-3">
            <button type="button" class="btn btn-primary" @click="onAddItem">Add Question
            </button>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

    export default {
        components: {
            draggable,
        },
        data() {
            return {
                editor: ClassicEditor,
                editorConfig: {
                    toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
                    placeholder: 'Enter answer here',
                },
                content: this.value,
            }
        },
        props: {
            name: {
                type: String,
                require: true,
            },
            value: {
                type: Array,
                require: true,
            }
        },
        methods: {
            onAddItem() {
                this.content.push({
                    question: '',
                    answer: ''
                });
            },
            onRemoveItem(index) {
                this.content.splice(index, 1);
            }
        }
    }
</script>
