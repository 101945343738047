/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import UUID from "vue-uuid";
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import VModal from 'vue-js-modal';
import CKEditor from '@ckeditor/ckeditor5-vue2';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import Vue from "vue";

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('workout-attributes-wrapper', require('./components/WorkoutAttributesWrapperComponent.vue').default);
Vue.component('workout-attribute', require('./components/WorkoutAttributeComponent.vue').default);
Vue.component('exercise-wrapper', require('./components/ExerciseWrapperComponent.vue').default);
Vue.component('add-exercise', require('./components/AddExerciseComponent.vue').default);
Vue.component('exercise-set', require('./components/ExerciseSetComponent.vue').default);
Vue.component('exercise-swap-wrapper', require('./components/ExerciseSwapWrapperComponent').default);
Vue.component('exercise-swap', require('./components/ExerciseSwapComponent').default);
Vue.component('input-image', require('./components/InputImageComponent').default);
Vue.component('featured-workout', require('./components/FeaturedWorkoutComponent').default);
Vue.component('category-workouts', require('./components/CategoryWorkoutsComponent').default);
Vue.component('featured-items-wrapper', require('./components/FeaturedItemsWrapperComponent').default);
Vue.component('featured-item', require('./components/FeaturedItemComponent').default);
Vue.component('dashboard-category', require('./components/DashboardCategoryComponent').default);
Vue.component('create-workout-information', require('./components/CreateWorkoutInformationComponent').default);
Vue.component('create-workout-exercises', require('./components/CreateWorkoutExercisesComponent').default);
Vue.component('workout-combine', require('./components/WorkoutCombineComponent').default);
Vue.component('workout-similar', require('./components/WorkoutSimilarComponent').default);
Vue.component('workout-rounds', require('./components/WorkoutRoundsComponent').default);
Vue.component('exercise-superset', require('./components/ExerciseSupersetComponent').default);
Vue.component('workout-exercise', require('./components/WorkoutExerciseComponent').default);
Vue.component('help-page-content', require('./components/HelpPageContentComponent').default);
Vue.component('list-exercise-categories', require('./components/ListExerciseCategoriesComponent').default);
Vue.component('list-workout-categories', require('./components/ListWorkoutCategoriesComponent').default);
Vue.component('image-input', require('./components/ImageInputComponent').default);

Vue.use(UUID);
Vue.use(VueSimpleAccordion);
Vue.use(VModal);
Vue.use(CKEditor);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


