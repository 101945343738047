<template>
    <tr>
        <td>
            <input
                type="text"
                :name="`${name}[key]`"
                :value="label"
                placeholder="e.g. Main Lifts"
                class="form-control"
            ></td>
        <td>
            <input
                type="text"
                :name="`${name}[value]`"
                :value="value"
                placeholder="e.g. Squat, Bench Press"
                class="form-control"
            >
        </td>
        <td>
            <a href="#" class="btn remove-tr" @click="remove">
                <span class="fa fa-minus" aria-hidden="true"></span>
            </a>
        </td>
    </tr>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                require: true,
            },
            label: {
                type: String,
                require: false,
            },
            value: {
                type: String,
                require: false,
            },
        },
        methods: {
            remove(event) {
                event.preventDefault();
                this.$emit('remove');
            },
        }
    }
</script>
