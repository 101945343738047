<template>
    <div>
        <label v-if="title" class="col-sm-4 col-md-3 control-label">{{ title }}</label>
        <draggable
            :list="selectedWorkouts"
            class="list-group"
            handle=".handle"
            tag="ul"
        >
            <li
                v-for="(workout, index) of selectedWorkouts"
                :key="workout.id + '-' + index"
                class="list-group-item">
                <i class="fa fa-align-justify handle"></i>
                <i
                    class="fa fa-minus align-right"
                    style="float: right"
                    @click="onRemoveItem(index)"
                ></i>
                <input
                    :name="inputName + '[' + index + ']'"
                    :value="workout.id"
                    class="d-none"
                    type="number"
                >
                {{ workout.title }}
            </li>
        </draggable>
        <div class="clearfix">
            <button
                class="btn btn-default margin-top-10"
                type="button"
                @click="openModal">Add Combinable Workout
            </button>
        </div>
        <modal :name="inputName" adaptable="true" height="auto">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Combinable Workout</h4>
                    <button type="button" class="close" @click="dismissModal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label class="col-form-label">Select a Workout</label>
                    <select2 :options="allWorkoutsFetch"  v-model="selectedWorkout">
                        <option disabled :value="null">-- Select a Workout --</option>
                    </select2>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" @click="dismissModal">Close</button>
                    <button type="button" v-bind:class="{ disabled: !selectedWorkout }" class="btn btn-primary"
                            @click="selectWorkout">Select Workout
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import Select2 from './Select2.vue';

    export default {
        components: {
            draggable,
            "select2": Select2
        },
        props: {
            initialValues: {
                type: Array,
                require: false
            },
            inputName: {
                type: String,
                require: true,
            },
            title: {
                type: String,
                require: false,
            },
            workouts: {
                type: [Array, String],
                require: true,
            },
        },
        data() {
            const allWorkouts = typeof this.workouts === 'string' ? Function('return window.' + this.workouts)() : this.workouts;
            const selectedWorkouts = [];

            if (this.initialValues) {
                for (const id of this.initialValues) {
                    const workout = allWorkouts.find(workout => parseInt(workout.id) === parseInt(id));
                    if (workout) selectedWorkouts.push(workout);
                }
            }

            return {
                allWorkouts,
                selectedWorkouts,
                selectedWorkout: null,
            }
        },
        computed: {
            allWorkoutsFetch() {
                return this.allWorkouts.map(workout => {
                    return {
                        id: workout.id,
                        text: workout.title
                    }
                });
            },
        },
        methods: {
            onRemoveItem(index) {
                this.selectedWorkouts.splice(index, 1);
            },
            openModal() {
                this.$modal.show(this.inputName);
            },
            dismissModal() {
                this.$modal.hide(this.inputName);
                this.selectedWorkout = null;
            },
            selectWorkout() {
                const selection = this.allWorkouts.find(item => parseInt(item.id) === parseInt(this.selectedWorkout));

                this.selectedWorkouts.push(selection);
                this.dismissModal();
            },
        }
    }
</script>
