<template>
  <div>
    <div class="form-group">
      <input v-model="featuredItem" type="checkbox">
      <label>Featured Item</label>
    </div>
    <div v-if="featuredItem">
      <label class="col-sm-4 col-md-3 control-label">Workout</label>
      <div class="col-sm-8 col-md-9">
        <select
            :name="inputName + '[workout_id]'"
            class="form-control select2 select2-hidden-accessible">
          <option value="">- None -</option>
          <option
              v-for="(item, index) of featuredWorkouts"
              :key="index"
              :value="item.id"
          >{{ item.title }}
          </option>
        </select>
        <input :name="inputName + '[type]'" class="d-none" type="text" value="featured">
      </div>
    </div>
    <div v-if="!featuredItem">
      <label class="col-sm-4 col-md-3 control-label">Workout</label>
      <div class="col-sm-8 col-md-9">
        <select
            :name="inputName + '[workout_id]'"
            class="form-control select2 select2-hidden-accessible">
          <option value="">- None -</option>
          <option
              v-for="(item, index) of workouts"
              :key="index"
              :value="item.id"
          >{{ item.title }}
          </option>
        </select>
        <input :name="inputName + '[type]'" class="d-none" type="text" value="regular">
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  props: {
    featuredWorkouts: {
      type: Array,
      require: true
    },
    workouts: {
      type: Array,
      require: true,
    },
    inputName: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      featuredItem: true,
    }
  }
}
</script>
