<template>
  <div>
    <draggable
        :list="workoutExercises"
        class="list-group"
        handle=".handle"
        tag="ul"
    >
      <li
          v-for="(exercise, index) of workoutExercises"
          :key="exercise.id + '-' + index"
          class="list-group-item">
        <i class="fa fa-align-justify handle"></i>
        <i
            class="fa fa-minus align-right"
            style="float: right"
            @click="onRemoveItem(index)"
        ></i>
        <div
            class="form-group">
          <input
              :value="workoutExercises[index].name"
              class="form-control"
              disabled
              type="text"
          >
          <input
              v-model="workoutExercises[index].id"
              :name="'exercises[' + index + ']' + '[selected_exercise]'"
              class="d-none"
          >
          <div class="form-group">
            <label>Left Column</label>
            <select
                v-model="workoutExercises[index].attributes.leftColumn"
                class="form-control select2"
                :name="'exercises[' + index + ']' + '[left_column]'"
            >
              <option
                  v-for="(attribute, index) of exerciseAttributes"
                  :key="index"
                  :value="index"
              >{{ index }}
              </option>
            </select>
            <label>Right Column</label>
            <select
                v-model="workoutExercises[index].attributes.rightColumn"
                class="form-control select2"
                :name="'exercises[' + index + ']' + '[right_column]'"
            >
              <option
                  v-for="(option, index) of exerciseAttributes[workoutExercises[index].attributes.leftColumn]"
                  :key="index"
                  :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <draggable
              :list="workoutExercises[index].sets"
              class="list-group"
              handle=".handle-div"
              tag="div"
          >
            <div
                v-for="(set, setIndex) of exercise.sets"
                :key="setIndex + exercise.id"
                class="form-inline">
              <i class="fa fa-align-justify handle-div"></i>
              <label>{{ exercise.attributes.leftColumn }}</label>
              <input
                  v-model="workoutExercises[index].sets[setIndex].leftValue"
                  :name="'exercises[' + index + ']' + '[sets][' + setIndex + '][left_value]'"
                  class="form-control"
                  type="text"
              >
              <label>{{ exercise.attributes.rightColumn }}</label>
              <input
                  v-model="workoutExercises[index].sets[setIndex].rightValue"
                  :name="'exercises[' + index + ']' + '[sets][' + setIndex + '][right_value]'"
                  class="form-control"
                  type="text"
              >
              <i
                  class="fa fa-minus align-right"
                  @click="removeSet(index, setIndex)"
              ></i>
            </div>
          </draggable>
          <div class="clearfix">
            <button
                class="btn btn-default"
                type="button"
                @click="addSet(index)">Add set
            </button>
          </div>
        </div>
      </li>
    </draggable>
    <div class="clearfix">
      <button
          class="btn btn-default"
          type="button"
          @click="openModal">Add Exercise
      </button>
    </div>
    <modal name="add-exercise">
      <div class="form-group">
        <label class="col-sm-4 col-md-3 control-label">Select an Exercise</label>
          <select2 :options="exerciseOptionsFetch"  v-model="selectedExercise">
              <option disabled :value="null">-- Select an Exercise --</option>
          </select2>
      </div>
      <div class="clearfix">
        <button
            class="btn btn-default"
            @click="closeModal">Add
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import Select2 from './Select2.vue';

export default {
  components: {
    draggable,
      "select2": Select2
  },
  mounted() {
    this.options = this.exerciseOptions.map(item => {
      return {
        ...item,
        attributes: {
          leftColumn: this.exerciseAttributes[0],
          rightColumn: null,
        },
        sets: [
          {
            leftValue: '',
            rightValue: '',
          },
        ],
      };
    });
    this.workoutExercises.push(this.options[0]);
  },
    computed: {
        exerciseOptionsFetch() {
            var vm = this
            return _.map(vm.exerciseOptions, function (data) {
                var pick = _.pick(data, 'name', 'id');
                return {id: pick.id, text: pick.name}
            })
        },
    },
  props: {
    exerciseAttributes: {
      type: Object,
      require: true,
    },
    exerciseOptions: {
      type: Array,
      require: true,
    }
  },
  data() {
    return {
      workoutExercises: [],
      options: [],
      selectedExercise: null,
    }
  },
  methods: {
    onRemoveItem(index) {
      this.workoutExercises.splice(index, 1);
    },
    addExercise() {
      this.workoutExercises.push(this.options[0]);
    },
    addSet(index) {
      this.workoutExercises[index].sets.push({
        leftValue: '',
        rightValue: '',
      });
    },
    removeSet(index, setIndex) {
      this.workoutExercises[index].sets.splice(setIndex, 1);
    },
    openModal() {
      this.$modal.show('add-exercise',
          {exerciseOptions: this.exerciseOptions});
    },
    closeModal() {
      this.$modal.hide('add-exercise');
      this.workoutExercises.push({
        ...this.selectedExercise,
        attributes: {
          leftColumn: this.exerciseAttributes[0],
          rightColumn: null,
        },
        sets: [
          {
            leftValue: '',
            rightValue: '',
          },
        ],
      });
      this.selectedExercise = null;
    },
  },
}
</script>
