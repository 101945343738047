<template>
    <div>
        <draggable :list="exercises" handle=".drag-handle" tag="div">
            <div
                v-for="(item, index) of exercises"
                :key="`${item.id}-${index}`"
                class="card card-default "
                v-bind:class="item.type"
            >
                <template v-if="item.type === 'superset'">
                    <div
                        class="card-header"
                        @click="toggleOpen(item)"
                        style="cursor: pointer"
                    >
                        <h4 class="card-title">
                            <i class="fa fa-align-justify drag-handle mr-2"></i>
                            Superset
                        </h4>
                        <i
                            :class="{
                                'fa-chevron-down': item.isOpen,
                                'fa-chevron-up': !item.isOpen
                            }"
                            class="fa mt-1 float-right"
                        ></i>
                    </div>
                    <div class="card-body custom-superset-body" :class="{ collapse: !item.isOpen }">
                        <exercise-superset
                            :exerciseAttributes="exerciseAttributes"
                            :exerciseOptions="exerciseOptions"
                            :inputName="'exercises[' + index + ']'"
                            :superset="item"
                        >
                        </exercise-superset>
                    </div>
                    <div
                        class="card-footer"
                        :class="{ collapse: !item.isOpen }"
                    >
                        <div class="clearfix">
                            <button
                                class="btn btn-danger float-right"
                                type="button"
                                @click="removeExercise(index)"
                            >
                                Remove Superset
                            </button>
                        </div>
                    </div>
                </template>
                <template v-if="item.type === 'exercise'">
                    <div
                        class="card-header"
                        @click="toggleOpen(item)"
                        style="cursor: pointer"
                    >
                        <h4 class="card-title">
                            <i class="fa fa-align-justify drag-handle mr-2"></i>
                            {{ item.name }}
                        </h4>
                        <i
                            :class="{
                                'fa-chevron-down': item.isOpen,
                                'fa-chevron-up': !item.isOpen
                            }"
                            class="fa mt-1 float-right"
                        ></i>
                    </div>
                    <div class="card-body" :class="{ collapse: !item.isOpen }">
                        <workout-exercise
                            :ref="'workout-exercise-' + index"
                            :exercise="item"
                            :exerciseAttributes="exerciseAttributes"
                            :inputName="'exercises[' + index + ']'"
                            :supersetIdentifier="null"
                        >
                        </workout-exercise>
                    </div>
                    <div
                        class="card-footer"
                        :class="{ collapse: !item.isOpen }"
                    >
                        <div class="clearfix">
                            <button
                                class="btn btn-primary float-left"
                                type="button"
                                @click="addSet(index)"
                            >
                                Add Set
                            </button>
                            <button
                                class="btn btn-danger float-right"
                                type="button"
                                @click="removeExercise(index)"
                            >
                                Remove Exercise
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </draggable>
        <div class="clearfix">
            <button
                class="btn btn-default margin-top-10"
                @click="openModal"
                type="button"
            >
                Add Exercise
            </button>
            <button
                class="btn btn-default margin-top-10"
                @click="addSuperset"
                type="button"
            >
                Add Superset
            </button>
        </div>
        <modal name="add-exercise" adaptable="true" height="auto">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Add Exercise</h4>
                    <button
                        type="button"
                        class="close"
                        @click="dismissModal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label class="col-form-label">Select an Exercise</label>

                    <select2 :options="exerciseOptionsFetch" v-model="selectedExercise">
                        <option disabled :value="null">-- Select an Exercise --</option>
                    </select2>

                </div>
                <div class="modal-footer justify-content-between">
                    <button
                        type="button"
                        class="btn btn-default"
                        @click="dismissModal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        v-bind:class="{ disabled: !selectedExercise }"
                        class="btn btn-primary"
                        @click="selectExercise"
                    >
                        Select Exercise
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import {uuid} from "vue-uuid";

import Select2 from './Select2.vue';

export default {

    components: {
        draggable,
        "select2": Select2
    },
    data() {
        return {
            options: [],
            exercises: [],
            selectedExercise: null
        };
    },
    mounted() {
        this.options = this.exerciseOptions;
        this.exercises = [];

        if (this.initialValues) {
            this.initialValues.forEach(item => {
                this.exercises.push({
                    ...item,
                    isOpen: false
                });
            });
        }

    },
    computed: {
        exerciseOptionsFetch() {
            return this.exerciseOptions.map(exercise => {
                return {
                    id: exercise.id,
                    text: exercise.name
                }
            });
        },
    },
    props: {
        initialValues: {
            type: Array,
            require: false
        },
        exerciseOptions: {
            type: Array,
            require: true
        },
        exerciseAttributes: {
            type: Object,
            require: true
        },
        selectedExercises: {
            type: Array,
            require: true
        }
    },
    methods: {
        addSet(index) {
            const [workoutExercise] = this.$refs["workout-exercise-" + index];
            workoutExercise.addSet();
        },
        addSuperset() {
            this.exercises.push({
                isOpen: true,
                id: uuid.v1(),
                type: "superset",
                exercises: []
            });
        },
        dismissModal() {
            this.$modal.hide("add-exercise");
            this.selectedExercise = null;
        },
        openModal() {
            this.$modal.show("add-exercise", {
                exerciseOptions: this.exerciseOptions
            });
        },
        removeExercise(index) {
            this.exercises.splice(index, 1);
        },
        selectExercise() {

            const selection = this.exerciseOptions.find(item => parseInt(item.id) === parseInt(this.selectedExercise));

            this.exercises.push({
                id: selection.id,
                name: selection.name,
                type: "exercise",
                isOpen: true,
                text_overwrite: selection.text_overwrite,
                value_overwrite: selection.value_overwrite,
                sets: {
                    left: null,
                    right: null,
                    values: [[0, 0]]
                }
            });
            this.dismissModal();
        },
        toggleOpen(item) {
            item.isOpen = !item.isOpen;
        }
    },
};
</script>
