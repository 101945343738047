<template>
    <table id="workout_information" class="table mb-0">
        <thead>
        <tr>
            <th>Label</th>
            <th>Value</th>
            <th>
                <button
                    id="add_row"
                    class="btn btn-primary btn-add"
                    @click="addRow"
                >
          <span aria-hidden="true" class="fa fa-plus">
          </span>
                </button>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(attribute, index) of workoutAttributes"
            :key="index"
        >
            <td>
                <input
                    v-model="attribute.leftValue"
                    :name="'workout_attributes[' + index + '][key]'"
                    class="form-control"
                    placeholder="e.g. Main Lifts"
                    type="text"
                >
            </td>
            <td>
                <input
                    v-model="attribute.rightValue"
                    :name="'workout_attributes[' + index + '][value]'"
                    class="form-control"
                    placeholder="e.g. Squat, Bench Press"
                    type="text"
                >
            </td>
            <td>
                <button
                    class="btn remove-tr"
                    @click="onRemoveSet(index)"
                >
        <span aria-hidden="true" class="fa fa-minus">
        </span>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
  data() {
    return {
      workoutAttributes: [
        {
          leftValue: '',
          rightValue: '',
        }
      ]
    }
  },
  methods: {
    addRow() {
      this.workoutAttributes.push({
        leftValue: '',
        rightValue: '',
      });
    },
    onRemoveSet(index) {
      this.workoutAttributes.splice(index, 1);
    }
  }
}
</script>
